<template>
  <div>
    <Table
      title-key="user.username"
      :actions="actions"
      :items="$store.state.report.tableData"
      :fields="$store.getters['report/tableFields']"
      :loading="$store.state.report.loadingTable"
      :filter="$store.state.report.tableFilter"
      :sort="$store.state.report.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      :keyword="$store.state.report.tableKeyword"
      :pagination="$store.state.report.tablePagination"
      :selected-ids="$store.state.report.selectedIds"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
      @select="select"
      @download="downloadData"
    />

    <!-- Approve confirmation modal -->
    <b-modal
      v-model="approvalModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="white"
      @ok="approve"
    >
      Are you sure you want to approve this report request?
    </b-modal>

    <!-- Reject confirmation modal -->
    <b-modal
      v-model="rejectionModalVisible"
      title="Reject"
      cancel-variant="white"
      ok-title="Yes, reject"
      @ok="reject"
    >
      <p>Are you sure you want to reject this report request?</p>
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    Table,
    BModal,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      approvalMultipleRowModalVisible: false,
      rejectionModalVisible: false,
      rejectionMultipleRowModalVisible: false,
      refundModalVisible: false,
      refundMultipleRowModalVisible: false,

      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      approvalFields: [
        {
          key: 'description',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      rejectionFields: [
        {
          key: 'reason',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      actions: [
        'view',
        {
          type: 'other',
          children: [
            {
              label: 'Approve',
              event: this.confirmApprove,
              visibility: {
                callback: data => data.status === 'pending' && this.canAccess('manage', 'Report'),
              },
            },
            {
              label: 'Reject',
              event: this.confirmReject,
              visibility: {
                callback: data => data.status === 'pending' && this.canAccess('manage', 'Report'),
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    tabOptions() {
      return [
        { key: 'status', label: 'All', default: true },
        { key: 'status', label: 'Approved', value: 'approved' },
        { key: 'status', label: 'Rejected', value: 'rejected' },
      ]
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('report/getTableData')
    },
    changeFields(val) {
      this.$store.commit('report/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('report/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('report/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('report/SET_TABLE_SORT', val)
    },
    confirmApprove(data) {
      this.selectedData = data.item
      this.approvalModalVisible = true
    },
    approve() {
      this.$store.dispatch('report/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'approved',
          entityID: this.selectedData.entityID,
          entityType: this.selectedData.entityType,
          remark: this.selectedData.remark,
          type: this.selectedData.type,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The report has been approved',
              variant: 'success',
            },
          })

          this.approvalModalVisible = false
          this.loadData()
        })
    },
    confirmReject(data) {
      this.selectedData = data.item
      this.rejectionModalVisible = true
    },
    reject(data, callback) {
      this.$store.dispatch('report/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'rejected',
          entityID: this.selectedData.entityID,
          entityType: this.selectedData.entityType,
          remark: this.selectedData.remark,
          type: this.selectedData.type,
        },
      })
        .then(() => {
          this.rejectionModalVisible = false
          this.loadData()

          callback()
        })
    },
    paginate(val) {
      this.$store.commit('report/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('report/SET_TABLE_KEYWORD', val)
    },
    select(val) {
      this.$store.commit('report/SET_SELECTED_IDS', val)
    },
    downloadData() {
      return this.$store.dispatch('report/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
